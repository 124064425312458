import React, { useCallback, useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from '@mui/material';
import api from '../../services/api';

const ForgotPasswordPage: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState<boolean>(false);

  const checkPassword = useCallback(async (email: string) => {
    const response = await api.post('/users/checkPassword', {
      email,
      isReset: true,
    });
    return response.data;
  }, []);

  const validateEmail = (email: string): boolean => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return true; // is not valid
    }
    return false; // is valid
  };

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const email = data?.get('email')?.toString().toLowerCase() ?? '';

      const isEmailInvalid = validateEmail(email);
      if (!isEmailInvalid) {
        // talvez seja necessario enviar mais um parametro para o backend saber quando é reset de senha ou quando é primeiro login
        // outra opçao é criar um fluxo somente para o reset senha
        const hasPassword = await checkPassword(email);
        if (sentEmail) {
          setMessage(
            "*We will send again a new link. If doesn't work, please check if you typed your email correctly or contact us",
          );
        } else {
          setMessage(
            hasPassword.status
              ? 'The systems found a problem to redefine password. Please contact us.'
              : hasPassword.message,
          );
        }

        setEmailError(false);
        setSentEmail(true);
      } else {
        setEmailError(true);
      }
    },
    [checkPassword, sentEmail],
  );

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Paper
          elevation={10}
          sx={{ m: 1, width: '45%', height: '60%', borderRadius: '15px' }}
        >
          <div
            style={{
              margin: '15px',
              height: '94%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ height: '120px' }}>
              <h2 style={{ margin: 0 }}>Forgot Password</h2>
              <hr
                style={{
                  borderWidth: '0px',
                  borderStyle: 'solid',
                  borderColor: '#E5EAF2',
                  borderBottomWidth: 'thin',
                  marginTop: '12px',
                  marginBottom: '15px',
                }}
                className="MuiDivider-root MuiDivider-fullWidth css-1vdax5c"
              ></hr>
              <span>
                Lost your password? Please enter your email address. You will
                receive a link to create a new password via email.
              </span>
            </div>

            <div style={{ height: '240px' }}>
              <TextField
                style={{ width: '100%' }}
                id="standard-basic"
                label="Email"
                name="email"
                variant="standard"
                error={emailError}
                helperText={emailError ? 'Invalid email format' : ''}
              />
              <Button
                type="submit"
                style={{ marginTop: '20px' }}
                variant="outlined"
                disabled={sentEmail}
              >
                Reset Password
              </Button>
              {sentEmail && (
                <Button
                  type="submit"
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                  variant="outlined"
                >
                  Try again
                </Button>
              )}

              {message !== '' && (
                <h4 style={{ color: '#4D50FF', fontWeight: 600 }}>{message}</h4>
              )}
            </div>

            <div style={{ height: '50px' }}>
              <hr
                style={{
                  borderWidth: '0px',
                  borderStyle: 'solid',
                  borderColor: '#E5EAF2',
                  borderBottomWidth: 'thin',
                  marginTop: '12px',
                  marginBottom: '5px',
                }}
                className="MuiDivider-root MuiDivider-fullWidth css-1vdax5c"
              ></hr>
              <Link href="/login" variant="body2">
                Remember your password?
              </Link>
            </div>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default ForgotPasswordPage;
