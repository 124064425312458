import axios from 'axios';

if (!process.env.REACT_APP_BACKEND_BASE_URL) {
  throw new Error("'REACT_APP_BACKEND_BASE_URL' not defined.");
}

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

export default api;
